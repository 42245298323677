import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  showTab() {
    $('#myTab').find('.nav-link').each((index, element) => {
      if ($(element).hasClass('error-tab')) {
        $(element).trigger('click');
        return false;
      }
    })
  }

  initialize() {
    $('#myTab').find('.nav-link').filter(':first').trigger('click');
    this.showTab();
  }

  connect() {
    console.log('START TABLIST');
  }
}
