import $ from 'jquery'
import Rails from '@rails/ujs'
Rails.start()

import 'cocoon-js'
import 'select2'
import 'select2/select2_locale_pt-BR'
import 'qtip2'
import 'bootstrap'
import 'controllers'

import flatPickr from 'flatpickr'
import { Portuguese } from 'flatpickr/dist/l10n/pt'

import moment from 'moment'
window.moment = moment

import NProgress from 'nprogress'
window.NProgress = NProgress

import Turbolinks from 'turbolinks'
Turbolinks.start()
Turbolinks.setProgressBarDelay(250)

import 'stylesheets/application'
